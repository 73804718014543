<template>
  <Card class="screen">
    <div class="screen__content">
      <div class="screen--mobile-top">
        <div class="screen__online">
          <inline-svg
            v-if="screen.orientation === 'landscape'"
            class="screen__icon"
            :src="require(`../assets/icons/Screen.svg`)"
            aria-label="Screen"
          ></inline-svg>
          <inline-svg
            v-if="screen.orientation === 'portrait'"
            class="screen__icon"
            :src="require(`../assets/icons/Portrait.svg`)"
            aria-label="Screen"
          ></inline-svg>
          <div
            class="screen__online-dot"
            :class="{ 'screen__online-dot--online': isOnline }"
          ></div>
        </div>
        <div class="screen__input-field">
          <input class="screen__name" v-model="name" />
          <Button
            class="screen__name-confirm-btn"
            theme="confirm"
            @click="onSaveScreenName"
            v-if="showSaveButton"
          >
            <inline-svg
              class="screen__confirm-icon"
              :src="require(`../assets/icons/save.svg`)"
              aria-label="Save"
            ></inline-svg>
          </Button>
        </div>
      </div>

      <div class="screen--mobile-bottom">
        <DropDown
          class="screen__orientation"
          v-model="screen.orientation"
          :list="orientations"
        >
          <template slot="selected">
            <div class="screen__orientation-option">
              {{ $t(`dropdown.${screen.orientation}`) }}
            </div>
          </template>
        </DropDown>

        <Button
          theme="secondary"
          class="screen__button"
          @click="openPairingModal"
        >
          {{ $t("components.screen.pairButton") }}
        </Button>
        <Button
          theme="secondary"
          class="screen__button--tag"
          @click="showAddScreenTagModal = true"
        >
          <inline-svg
            class="screen__tag-icon"
            :src="require(`../assets/icons/Tag.svg`)"
            aria-label="Screen Tag Button"
          ></inline-svg>
        </Button>
        <Button
          class="screen__button--preview"
          theme="secondary"
          @click="showSlidePreview"
        >
          <inline-svg
            class="screen__preview-icon"
            :src="require('../assets/icons/Preview.svg')"
            aria-label="Preview Icon"
          ></inline-svg>
        </Button>
      </div>
    </div>

    <div class="screen__tags" v-if="tagsWithNames.length > 0">
      <Tag
        :tag="tag"
        class="screen__tag"
        v-for="(tag, index) in tagsWithNames"
        :key="index"
        :active="true"
      />
    </div>

    <Modal
      class="screen__modal"
      v-if="showCodeModal"
      @close="showCodeModal = false"
      :heading="$t('components.screen.modal.title')"
    >
      <template #modal-content>
        <div class="screen__modal-description">
          {{ $t("components.screen.modal.description") }}
        </div>
        <input class="screen__modal-input" v-model="code" />
        <Button class="screen__modal-btn" @click="onPairScreen">{{
          $t("components.screen.modal.pairButton")
        }}</Button>
      </template>
    </Modal>

    <AddScreenTagModal
      v-if="showAddScreenTagModal"
      @close="showAddScreenTagModal = false"
      :screen-id="screen.id"
    />

    <Modal
      v-if="showPreviewIFrame"
      @close="showPreviewIFrame = false"
      heading="Beta Preview"
    >
      <template #modal-content>
        <iframe
          :class="screenPreviewClass"
          title="screen preview"
          :src="previewUrl"
        />
      </template>
    </Modal>
  </Card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";
import Card from "./Card";
import Tag from "./Tag";
import Modal from "./Modal.vue";
import AddScreenTagModal from "./AddScreenTagModal.vue";
import Button from "./Button.vue";
import DropDown from "./DropDown.vue";

export default {
  props: {
    screen: {
      type: Object,
      default: () => {}
    },
    tags: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    }
  },
  components: {
    Tag,
    Card,
    Modal,
    Button,
    DropDown,
    AddScreenTagModal
  },
  data: () => ({
    showDropdown: false,
    code: null,
    showCodeModal: false,
    showAddScreenTagModal: false,
    orientations: ["landscape", "portrait"],
    name: "",
    showSaveButton: false,
    isOnline: false,
    doLoop: true,
    showPreviewIFrame: false
  }),
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam"
    }),
    screenPreviewClass() {
      return `screen__preview--${this.screen.orientation}`;
    },
    tagsWithNames() {
      if (
        this.tags &&
        Object.keys(this.tags).length !== 0 &&
        this.screen.tags &&
        this.screen.tags.length > 0
      ) {
        return this.screen.tags.map(tag => {
          return this.tags[tag.id];
        });
      }

      return [];
    },
    previewUrl() {
      if (this.screen && this.screen.id) {
        return `https://preview.apps.lobbyspace.me/?screenId=${this.screen.id}&teamId=${this.team.id}&orientation=${this.screen.orientation}`;
      }

      return "";
    }
  },
  watch: {
    name() {
      if (this.name !== this.screen.name && !this.showSaveButton) {
        this.showSaveButton = true;
      }
    },
    "screen.orientation"() {
      this.updateScreens();
    },
    "screen.timestamp"() {
      this.checkIfOnline();
    }
  },
  mounted() {
    this.name = this.screen.name;
    this.checkIfOnlineLoop();
  },
  beforeDestroy() {
    this.doLoop = false;
  },
  methods: {
    ...mapActions("screens", ["updateScreens", "pairScreen"]),
    checkIfOnline() {
      if (this.screen.timestamp) {
        let date = dayjs.unix(this.screen.timestamp / 1000); // since the value is saved in miliseconds we divide by 1000
        let now = dayjs();

        const difference = now.diff(date, "second");

        if (difference > 300) {
          this.isOnline = false;
        } else {
          this.isOnline = true;
        }
      } else {
        this.isOnline = false;
      }
    },
    checkIfOnlineLoop() {
      if (this.doLoop === false) return;
      if (this.screen.timestamp) {
        let date = dayjs.unix(this.screen.timestamp / 1000); // since the value is saved in miliseconds we divide by 1000
        let now = dayjs();

        const difference = now.diff(date, "second");

        if (difference > 300) {
          this.isOnline = false;
        } else {
          this.isOnline = true;
        }
      } else {
        this.isOnline = false;
      }

      setTimeout(() => {
        this.checkIfOnlineLoop();
      }, 10000);
    },
    openPairingModal() {
      this.showCodeModal = true;
    },
    onPairScreen() {
      let payload = {
        code: this.code,
        screenId: this.screen.id
      };
      this.pairScreen(payload).then(() => {
        this.showCodeModal = false;
      });
    },
    showSlidePreview() {
      this.showPreviewIFrame = true;
    },
    onDeleteTag() {
      delete this.screen.tag;
      this.updateScreens();
    },
    onSaveScreenName() {
      this.screen.name = this.name;
      this.updateScreens();
      this.showSaveButton = false;
    }
  }
};
</script>

<style lang="scss">
.screen {
  padding: 16px;
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__input-field {
    position: relative;
    width: 100%;
  }

  &__name-confirm-btn {
    position: absolute;
    top: 5px;
    right: 0px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &--mobile-top,
  &--mobile-bottom {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &--mobile-top {
    margin-bottom: 0.75rem;
  }

  &--mobile-bottom {
    justify-content: center;
  }

  &__tag {
    @apply mt-2 mr-2;
  }

  &__tag-icon {
    width: 16px;
    height: 16px;
  }

  &__online {
    @apply bg-ls-gray-300 p-3 rounded-full relative;

    &-dot {
      width: 12px;
      height: 12px;
      border-radius: 9999px;
      @apply bg-ls-red border-white border-2;
      position: absolute;
      bottom: 0;
      right: 0;

      &--online {
        @apply bg-ls-green;
      }
    }
  }

  &__preview--landscape {
    margin-top: 48px;
    width: 820px;
    height: 600px;
  }

  &__preview--portrait {
    margin-top: 48px;
    width: 900px;
    height: 820px;
  }

  &__name {
    @apply ml-2 px-2 py-3 bg-ls-gray-100 rounded-lg;
    flex: 1;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
  }

  &__button {
    white-space: nowrap;

    &--tag {
      @apply ml-2 mr-2;
    }
  }

  &__orientation {
    position: relative;
    margin-right: 1rem;
    min-width: 95px;
    @apply text-xs font-semibold text-ls-gray-800 flex items-center mr-6;

    .dropdown__selected-wrapper {
      border: none;
      padding: 0;
      width: 100%;
    }

    .dropdown__wrapper {
      top: 20px;
    }
  }

  &__modal {
    &-description {
      @apply text-ls-gray-800 mt-4;
    }

    &-input {
      @apply bg-ls-gray-100 border border-ls-gray-300 rounded-lg px-2 py-3 w-full mt-2 mb-4;
      font-size: 14px;
      line-height: 20px;
    }

    &-btn {
      width: 100%;
    }
  }
}

@media screen and (min-width: 900px) {
  .screen {
    &__content {
      flex-direction: row;
    }

    &--mobile-top {
      margin-bottom: 0;
    }

    &--mobile-bottom {
      margin-left: 1rem;
      width: unset;
    }

    &__modal {
      .modal__content {
        width: 480px;
      }
    }
  }
}
</style>
